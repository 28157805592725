<template>
  <div class="ticket_collec_setting_view">
    <div class="top_box">
      <div class="tit">收票设置</div>
    </div>
    <div class="count_box">
      <div class="num_box">
        <span style="margin-right: 50px">累计充值：{{ currency.total }}</span>
        <span
          >剩余喵点：<span class="num">{{ currency.cash }}</span></span
        >
      </div>
      <div>
        <el-tooltip class="item" effect="dark" placement="top" content="需要充值请联系平台运维人员" :disabled="false">
          <el-button type="primary" size="mini">立即充值</el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="opt_box">
      <div class="check_set">查验设置</div>
      <el-card class="box-card">
        <div class="opt_item">OCR识别：<el-switch :value="true" disabled> </el-switch></div>
        <div class="opt_item">自动查验：<el-switch v-model="checked"></el-switch><span class="tip">开启查验功能，系统会在识别票面后自动查验发票真伪。</span></div>
        <div class="opt_item" style="margin-bottom: 0px;">
          查验方式：
          <el-checkbox label="INV_RECEIVE_VERIFY_VERIFY_PLATFORM" v-model="verifyVal" :disabled="!checked">发票查验平台</el-checkbox>
          <el-checkbox label="INV_RECEIVE_VERIFY_SERVE_PLATFORM" v-model="serveVal" :disabled="!checked">综合服务平台</el-checkbox>
        </div>
      </el-card>
    </div>

    <div class="opt_box">
      <div class="check_set">收票设置</div>
      <el-card class="box-card">
         <div class="opt_item">
          识别结果展示方式：&emsp;&emsp;
          <el-radio-group v-model="identifyTheResult">
            <el-radio label="IMG">展示预览图</el-radio>
            <el-radio label="LIST">展示列表</el-radio>
          </el-radio-group>
        </div>
        <div class="opt_item" style="margin-bottom: 0px;">
          发票清单不全允许提交：
          <el-radio-group v-model="allowSybmit">
            <el-radio label="Y">允许提交&emsp;</el-radio>
            <el-radio label="N">不允许提交</el-radio>
          </el-radio-group>
          <span style="margin-left:20px; color:#999">(说明：本批次发票识别到有清单，且发票清单不完整时，允许或者不允许提交本批次发票)</span>
        </div>
      </el-card>
    </div>
    
  </div>
</template>

<script>
import { getCurrency } from '@/service/pay/currency';
import { getTntConfig, saveOrUpdateConfig } from '@/service/system/config';
export default {
  name: "ColYxgl",
  components: {},
  data() {
    return {
      identifyTheResult:'',
      SBJG:false,
      allowSybmit:'',
      FPQDTJ:false,
      checked: false,
      checkList: [],
      currency: {
        cash: 0,
        total: 0
      },
      verifyVal: false,
      serveVal: false,
      VS: false,
      VP: false,
      SP: false
    };
  },
  watch: {
    // 自动查验
    checked(val) {
      this.VS && this._checkedOpt(val, 'INV_RECEIVE_VERIFY_SWITCH', '租户:发票数据-收票业务-查验开关', '租户:发票数据-收票业务-查验开关');
      this.VS = true;
    },
    // 发票查验平台
    verifyVal(val) {
      this.VP && this._checkedOpt(val, 'INV_RECEIVE_VERIFY_VERIFY_PLATFORM', '发票数据-收票业务-查验方式-发票查验平台', '租户:发票数据-收票业务-发票查验平台开关');
      this.VP = true;
    },
    // 综合服务平台
    serveVal(val) {
      this.SP && this._checkedOpt(val, 'INV_RECEIVE_VERIFY_SERVE_PLATFORM', '发票数据-收票业务-查验方式-综合服务平台', '租户:发票数据-收票业务-综合服务平台开关');
      this.SP = true;
    },
    // 查询用户配置
    identifyTheResult(val){
      this.SBJG && this._handlerIdentifyTheResult(val, 'INV_RECEIVE_SHOWCASE', '发票数据-收票业务-识别结果展示方式', '租户:发票数据-收票业务-识别结果展示方式');
      this.SBJG = true;
    },
    // 发票清单不全时允许提交
    allowSybmit(val){
      this.FPQDTJ && this._checkedOpt(val, 'INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT', '发票数据-收票业务-发票清单不全时允许提交', '租户:发票数据-收票业务-发票清单不全时允许提交');
      this.FPQDTJ = true;
    }
  },

  created() {
    this.getCurrency();
    this.handleGetVerifySwitch('INV_RECEIVE_VERIFY_SWITCH');
    this.handleGetVerifySwitch('INV_RECEIVE_VERIFY_VERIFY_PLATFORM');
    this.handleGetVerifySwitch('INV_RECEIVE_VERIFY_SERVE_PLATFORM');
    this.handleGetVerifySwitch('INV_RECEIVE_SHOWCASE');
    this.handleGetVerifySwitch('INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT');
  },
  methods: {
    _checkedOpt(val, code, name, remark) {
      console.log(val, code, name, remark)
      if(code == 'INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT'){
        if(val == 'Y'){
          val = true
        }else {
          val = false
        }
      }else if (code == 'INV_RECEIVE_SHOWCASE') {
        if(val == 'IMG'){
          val = true
        }else {
          val = false
        }
      }
      const sysConfig = {
        code,
        value: val ? 'Y' : 'N',
        type: 'TNT',
        name,
        remark,
        status: '0'
      };
      console.log(sysConfig,'sysConfig')
      saveOrUpdateConfig(sysConfig).then(({ success }) => {
        if (!val && code == 'INV_RECEIVE_VERIFY_VERIFY_PLATFORM') {
          this.verifyVal = false;
          return;
        }
        if (!val && code == 'INV_RECEIVE_VERIFY_SERVE_PLATFORM') {
          this.serveVal = false;
          return;
        }
        if (!val && code == 'INV_RECEIVE_SHOWCASE') {
          this.identifyTheResult = 'LIST';
          return;
        }
        if (!val && code == 'INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT') {
          this.allowSybmit = 'N';
          return;
        }
        success && console.log('操作成功');
      });
    },
    _handlerIdentifyTheResult(val, code, name, remark) {
      console.log(val, code, name, remark)
      if(val == 'IMG'){
        val = true
      }else {
        val = false
      }
      const sysConfig = {
        code,
        value: val ? 'IMG' : 'LIST',
        type: 'TNT',
        name,
        remark,
        status: '0'
      };
      console.log(sysConfig,'sysConfig')
      saveOrUpdateConfig(sysConfig).then(({ success }) => {
        if (!val && code == 'INV_RECEIVE_SHOWCASE') {
          this.identifyTheResult = 'LIST';
          return;
        }
        success && console.log('操作成功');
      });
    },
    async handleGetVerifySwitch(type) {
      await getTntConfig(type).then(({ success, data }) => {
        if (success) {
          // console.log(data,'data成功')
          if (type == 'INV_RECEIVE_VERIFY_SWITCH') {
            this.checked = data == 'Y';
            if (data == 'N') {
              this.VS = true;
            }
          } else if (type == 'INV_RECEIVE_VERIFY_VERIFY_PLATFORM') {
            this.verifyVal = data == 'Y';
            if (data == 'N') {
              this.VP = true;
            }
          } else if (type == 'INV_RECEIVE_VERIFY_SERVE_PLATFORM') {
            this.serveVal = data == 'Y';
            if (data == 'N') {
              this.SP = true;
            }
          } else if(type == 'INV_RECEIVE_SHOWCASE'){
            this.identifyTheResult = data
            if (data == 'LIST') {
              this.SBJG = true;
            }
          }else if(type == 'INV_RECEIVE_INCOMPLETE_ALLOWED_TO_SUBMIT'){
            console.log(data,'data成功')
            this.allowSybmit = data
            if (data == 'N') {
              this.FPQDTJ = true;
            }
          }
        }
      });
    },
    //初始化剩余喵点
    async getCurrency() {
      const { success, data } = await getCurrency();
      if (success) this.currency = data;
    }
  }
};
</script>

<style scoped lang="scss">
.ticket_collec_setting_view {
  background: #fff;
  height: 100vh;
  box-sizing: border-box;
  .top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    padding: 16px 24px;
    .tit {
      border-left: 2px solid #999;
      padding-left: 10px;
      font-size: 16px;
      color: #333;
    }
  }
  .count_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 24px;
    margin: 26px 24px;
    background: #ebf4ff;
    .num_box {
      font-size: 14px;
      color: #333;
      .num {
        font-size: 36px;
        color: #333333;
        font-weight: 600;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
  .opt_box {
    padding: 0 24px;
    font-size: 14px;
    color: #333;
    .check_set {
      font-size: 16px;
      margin: 20px 0;
    }
    .opt_item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .tip {
        margin-left: 26px;
        color: #999;
      }
    }
  }
}
</style>
